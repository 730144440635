"use client";
import { useEffect } from "react";

import AOS from 'aos';

import 'aos/dist/aos.css';


export default function AosContainer({ children }) {
    useEffect(() => {
        AOS.init({
            startEvent: 'DOMContentLoaded',
            initClassName: 'aos-init',
            animatedClassName: 'aos-animate'
        });
    }, [])

    // useEffect(() => {
    //     // const call =  async  (token)=>{
    //     //     // await signIn("googleonetap",{id_token:token});
    //     //     await fetch("/api/google-one-tap/google",{
    //     //         method:"POST",
    //     //         body:JSON.stringify({token})
    //     //     })
    //     // }
    //
    //     // if (window.google) {
    //         window.google.accounts.id.initialize({
    //             client_id: process.env.GOOGLE_CLIENT_ID,
    //             callback: async (response) => {
    //                 await  signIn("googleonetap",{credential: response.credential,redirect:false});
    //                 // await fetch("/api/auth/callback/google",{
    //                 //     method:"POST",
    //                 //     body:JSON.stringify({token:response.credential})
    //                 // })
    //             },
    //         });
    //
    //         window.google.accounts.id.prompt((notification) => {
    //             if (notification.isNotDisplayed()) {
    //                 console.log(
    //                     'getNotDisplayedReason ::',
    //                     notification.getNotDisplayedReason()
    //                 );
    //             } else if (notification.isSkippedMoment()) {
    //                 console.log('getSkippedReason  ::', notification.getSkippedReason());
    //             } else if (notification.isDismissedMoment()) {
    //                 console.log(
    //                     'getDismissedReason ::',
    //                     notification.getDismissedReason()
    //                 );
    //             }
    //         });
    //     // }
    //
    // }, []);
    return  children ;

}
